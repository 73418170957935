// Uses @nuxt/scripts to load and bundle the typebot web SDK and provide it for use throughout the app

export function useTypebot() {
  const logger = useLogger()
  logger.log('Loading typebot script...')

  // Use nuxt script to load typebot script
  return useScript({
    src: 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.3.34/dist/web.js',
    type: 'module',
  }, {
    trigger: 'onNuxtReady',
    // The `use` function will only called client-side, it's used to resolve the API
    use() {
      return (window as any).Typebot
    },
  })
}

// Sets typebot variables from the user's profile and optional input
/**
 * Sets variables for the Typebot instance.
 *
 * @param vars - Optional record of additional variables to set.
 */
export function useGetTypebotVars(vars?: object) {
  useLogger().log('Setting typebot variables', '#bot', vars)

  // Get the current user
  const user = useSupabaseUser()

  // Default variables from runtime config
  const defaultVars = {
    ...useRuntimeConfig().public.bots.defaultBotVars,
  }

  // Environment vars
  const environmentVars = {
    apiBaseUrl: `${useRuntimeConfig().public.siteUrl}/api`,
    siteUrl: `${useRuntimeConfig().public.siteUrl}`,
  }

  // Extract user data from authentication
  const userAuthData = {
    userEmail: user.value?.email,
    userPhone: user.value?.phone,
    userAuthId: user.value?.id,
    // custom_claims: user.value?.user_metadata?.custom_claims,
    userAuthRole: user.value?.role,
    userIsAnonymous: user.value?.is_anonymous,
    userAuthCreatedAt: user.value?.created_at,
    userAuthUpdatedAt: user.value?.updated_at,
    userEmailConfirmedAt: user.value?.email_confirmed_at,
  }

  // Parse and extract user name information
  const parsedName = useParseUserNameFromAuth()
  const userNameParsed = {
    userParsedFirstName: parsedName?.first,
    userParsedLastName: parsedName?.last,
    userParsedFullName: parsedName?.full,
    userParsedShortName: parsedName?.short,
    userParsedPublicName: parsedName?.public,
    userParsedPrefix: parsedName?.prefix,
    userParsedSuffix: parsedName?.suffix,
    userParsedMiddleName: parsedName?.middle,
    userParsedInitials: parsedName?.initialsStr,
  }

  // Merge all variables, with later objects taking precedence
  const mergedVars = { ...vars, ...environmentVars, ...defaultVars, ...userNameParsed, ...userAuthData }

  useLogger().debug('useGetTypebotVars setting bot vars:', '#bot', { mergedVars })

  return {
    ...mergedVars,
  }
}

export function useSetTypebotVars(vars?: object) {
  // Get the Typebot instance
  const typebot = useTypebot()

  const allVars = useGetTypebotVars(vars)

  // Log the merged variables for debugging
  useLogger().debug(`Pre-filling vars in typebot instance:`, '#bot', {
    ...allVars,
  })

  // Set the merged variables in the Typebot instance
  typebot.setPrefilledVariables({ ...allVars })
}

// Function to initialize the typebot bubble
export function useLoadTypebot({
  botId,
  apiHost,
}: {
  botId?: string
  apiHost?: string
}) {
  const logger = useLogger()

  // Call useTypebot to get the typebot instance
  const typebot = useTypebot()

  if (!botId) {
    useLogger().warn(`No bot ID passed to useLoadTypebot - using default ${useRuntimeConfig().public.bots.defaultBotId}`)
  }

  // Set typebot vars
  useSetTypebotVars()

  // Initialise typebot bubble
  typebot.initBubble({
    typebot: botId || useRuntimeConfig().public.bots.defaultBotId, // Will use default if not provided
    apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
    previewMessage: useAppConfig().bots?.previewMessage,
    theme: useAppConfig().bots?.theme,
    onNewInputBlock: (inputBlock: any) => {
      logger.log('New input block displayed', '#bot', {
        inputBlockId: inputBlock.id,
      })
    },
    onAnswer: (answer: any) => {
      try {
        // Validate answer object
        if (!answer || typeof answer !== 'object') {
          throw new Error('Invalid answer object')
        }

        const message = answer.message ?? 'No message provided'
        const blockId = answer.blockId ?? 'Unknown block ID'

        // Log the answer
        logger.log('Answer received', '#bot', {
          message,
          blockId,
        })

        // Capture the event
        useEventCapture('bot_answer_received', {
          message,
          blockId,
        })

        // Additional error handling or processing can be added here
        // For example, you might want to sanitize the message or perform specific actions based on the blockId
      }
      catch (error) {
        // Log the error
        logger.error('Error processing bot answer', '#bot', {
          error: error instanceof Error ? error.message : 'Unknown error',
          answer,
        })

        // Optionally, you could also capture this error event
        useEventCapture('bot_answer_error', {
          error: error instanceof Error ? error.message : 'Unknown error',
        })
      }
    },
    onInit: () => {
      logger.log('Bot initialized', '#bot')
      try {
        // Capture the initialization event
        useEventCapture('bot_initialized', {
          botId: botId || useRuntimeConfig().public.bots.defaultBotId,
          apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
        })
      }
      catch (error) {
        logger.error('Error capturing bot initialization event', '#bot', {
          error: error instanceof Error ? error.message : 'Unknown error',
        })

        // Capture the error event
        useEventCapture('bot_initialization_error', {
          error: error instanceof Error ? error.message : 'Unknown error',
          botId: botId || useRuntimeConfig().public.bots.defaultBotId,
          apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
        })
      }
    },
    onEnd: () => {
      logger.log('Bot ended', '#bot')
    },
  })

  useLogger().log(`Typebot initialised`, '#useLoadTypebot')
}

// Function to initialize the typebot standard embed (using the <typebot-standard> component)
export function useLoadTypebotStandard({
  botId,
  apiHost,
}: {
  botId?: string
  apiHost?: string
}) {
  const logger = useLogger()

  // Call useTypebot to get the typebot instance
  const typebot = useTypebot()

  if (!botId) {
    useLogger().warn(`No bot ID passed to useLoadTypebot - using default ${useRuntimeConfig().public.bots.defaultBotId}`)
  }

  // Get typebot vars
  const allVars = useGetTypebotVars()
  useLogger().debug(`Typebot vars: ${JSON.stringify(allVars)}`, '#useLoadTypebot', {
    allVars,
  })

  // // Set typebot vars
  // useSetTypebotVars()

  // Initialise typebot standard
  typebot.initStandard({
    typebot: botId || useRuntimeConfig().public.bots.defaultBotId, // Will use default if not provided
    apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
    prefilledVariables: {
      ...allVars,
    },
    onNewInputBlock: (inputBlock: any) => {
      logger.log('New input block displayed', '#bot', {
        inputBlockId: inputBlock.id,
      })
    },
    onAnswer: (answer: any) => {
      try {
        // Validate answer object
        if (!answer || typeof answer !== 'object') {
          throw new Error('Invalid answer object')
        }

        const message = answer.message ?? 'No message provided'
        const blockId = answer.blockId ?? 'Unknown block ID'

        // Log the answer
        logger.log('Answer received', '#bot', {
          message,
          blockId,
        })

        // Capture the event
        useEventCapture('bot_answer_received', {
          message,
          blockId,
        })

        // Additional error handling or processing can be added here
        // For example, you might want to sanitize the message or perform specific actions based on the blockId
      }
      catch (error) {
        // Log the error
        logger.error('Error processing bot answer', '#bot', {
          error: error instanceof Error ? error.message : 'Unknown error',
          answer,
        })

        // Optionally, you could also capture this error event
        useEventCapture('bot_answer_error', {
          error: error instanceof Error ? error.message : 'Unknown error',
        })
      }
    },
    onInit: () => {
      logger.log('Bot initialized', '#bot')
      try {
        // Capture the initialization event
        useEventCapture('bot_initialized', {
          botId: botId || useRuntimeConfig().public.bots.defaultBotId,
          apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
        })
      }
      catch (error) {
        logger.error('Error capturing bot initialization event', '#bot', {
          error: error instanceof Error ? error.message : 'Unknown error',
        })

        // Capture the error event
        useEventCapture('bot_initialization_error', {
          error: error instanceof Error ? error.message : 'Unknown error',
          botId: botId || useRuntimeConfig().public.bots.defaultBotId,
          apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
        })
      }
    },
    onEnd: () => {
      logger.log('Bot ended', '#bot')
    },
  })

  useLogger().log(`Typebot initialised`, '#useLoadTypebot')
}

// Function to initialize the typebot bubble
export function useLoadTypebotBasic({
  botId,
  apiHost,
}: {
  botId?: string
  apiHost?: string
}) {
  const logger = useLogger()

  // Call useTypebot to get the typebot instance
  const typebot = useTypebot()

  if (!botId) {
    useLogger().warn(`No bot ID passed to useLoadTypebot - using default ${useRuntimeConfig().public.bots.defaultBotId}`)
  }

  // Set typebot vars
  // useSetTypebotVars()

  // Initialise typebot bubble
  typebot.initPopup({
    typebot: botId || useRuntimeConfig().public.bots.defaultBotId, // Will use default if not provided
    apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
    previewMessage: useAppConfig().bots?.previewMessage,
    // theme: useAppConfig().bots?.theme,
    autoShowDelay: 1000,
    onNewInputBlock: (inputBlock: any) => {
      logger.log('New input block displayed', '#bot', {
        inputBlockId: inputBlock.id,
      })
    },
    onAnswer: (answer: any) => {
      try {
        // Validate answer object
        if (!answer || typeof answer !== 'object') {
          throw new Error('Invalid answer object')
        }

        const message = answer.message ?? 'No message provided'
        const blockId = answer.blockId ?? 'Unknown block ID'

        // Log the answer
        logger.log('Answer received', '#bot', {
          message,
          blockId,
        })

        // Capture the event
        useEventCapture('bot_answer_received', {
          message,
          blockId,
        })

        // Additional error handling or processing can be added here
        // For example, you might want to sanitize the message or perform specific actions based on the blockId
      }
      catch (error) {
        // Log the error
        logger.error('Error processing bot answer', '#bot', {
          error: error instanceof Error ? error.message : 'Unknown error',
          answer,
        })

        // Optionally, you could also capture this error event
        useEventCapture('bot_answer_error', {
          error: error instanceof Error ? error.message : 'Unknown error',
        })
      }
    },
    onInit: () => {
      logger.log('Bot initialized', '#bot')
      try {
        // Capture the initialization event
        useEventCapture('bot_initialized', {
          botId: botId || useRuntimeConfig().public.bots.defaultBotId,
          apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
        })
      }
      catch (error) {
        logger.error('Error capturing bot initialization event', '#bot', {
          error: error instanceof Error ? error.message : 'Unknown error',
        })

        // Capture the error event
        useEventCapture('bot_initialization_error', {
          error: error instanceof Error ? error.message : 'Unknown error',
          botId: botId || useRuntimeConfig().public.bots.defaultBotId,
          apiHost: apiHost || useRuntimeConfig().public.bots.botApiHost,
        })
      }
    },
    onEnd: () => {
      logger.log('Bot ended', '#bot')
    },
  })

  useLogger().log(`Typebot initialised`, '#useLoadTypebot')
}
